.nav {
  display: flex;
  justify-content: space-between;
  background-color: black;
  color: white;
  padding: 15px;
  font-size: 2em;
}
.nav a {
  color: white;
  text-decoration: none;
}